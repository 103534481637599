import { ATTRIBUTES_UPDATED } from '../actions/user-attributes';

const INITIAL_STATE = {
  attributes: undefined
};

export const userAttributes = (state = INITIAL_STATE, action) => {
  if (action.type === ATTRIBUTES_UPDATED) {
    return {
      ...state,
      attributes: action.attributes
    };
  }
  return state;
};