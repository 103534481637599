import authService from './auth.service';
import { BaseService } from './base.service';
import configService from './config.service';
import { get } from './shared';
export class CanaryReleaseService extends BaseService {
  constructor(authService) {
    super();
    this.authService = authService;
    this.userGroupsCache = undefined;
  }

  async displayFeature() {
    const allowedGroups = configService.config.REVIEW_CANARY_GROUPS; 

    const userPrincipleName = await this.authService.getUserPrincipleName(); 

    if(userPrincipleName){
      const userGroupResponse = await get(this.buildApiUrl(`ad-groups/${userPrincipleName}`));

      if(userGroupResponse){
        const userGroupResult = await userGroupResponse.json();
        return userGroupResult.some((group) => allowedGroups.includes(group.toLowerCase()));
      }else{
        return false;
      }

    }

  }

  async isUserInSkillTestingGroup() {
    const userPrincipleName = await this.authService.getUserPrincipleName();
    let response = undefined;
    if(this.userGroupsCache){
      response = this.userGroupsCache;
    } else {
      const userGroups = await get(this.buildApiUrl(`ad-groups/${userPrincipleName}`));
      response = await userGroups.json();
      this.userGroupsCache = response;
    }
    const skillsTestingGroup = configService.config.SKILLS_TESTING_GROUP;
    return skillsTestingGroup.some(group=>response.includes(group))
  }
}

export default new CanaryReleaseService(authService);
