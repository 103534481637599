import { formatNumericDate } from "../services/format.service";

export const EDITING_QUEST_UPDATED = 'EDITING_QUEST_UPDATED';

export const editingQuestUpdated = (quest) => {
  if (quest.startDate && quest.months) {

    let newDate = new Date(quest.startDate);
    newDate.setMonth(newDate.getMonth() + parseInt(quest.months));

    quest = {
      ...quest,
      endDate: formatNumericDate(newDate)
    };
  }

  return {
    type: EDITING_QUEST_UPDATED,
    quest
  };
};
