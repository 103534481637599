import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-typescript';

class MarkdownService {
  rewriteMarkdownImagePaths(fileLocation, htmlText) {
    const imagePath = fileLocation.substr(0, fileLocation.lastIndexOf('/'));

    let regEx = /(img.*?)src="(.*?)"(\s)/gi;

    return htmlText.replace(regEx, `$1 src="courses/${imagePath}/$2" $3`);
  }

  addTargetToLinks (htmlText) {
    let regEx = /(<a)/gm;
    return htmlText.replace(regEx, `$1 target="_blank"`);
  }

  convertMarkdownToHtml(markdown, path) {
    let converter = new showdown.Converter({ tables: true });

    let htmlText = converter.makeHtml(markdown);

    if (path) htmlText = this.rewriteMarkdownImagePaths(path, htmlText);
    htmlText = this.addTargetToLinks(htmlText);

    const parser = new DOMParser();
    const element = parser.parseFromString(htmlText, 'text/html').documentElement;

    Prism.highlightAllUnder(element);

    return element;
  };
}

export default new MarkdownService;
