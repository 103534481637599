const wizzard = `
 ________________________           \/^\\ 
 | Welcome, adventurer!  |     \/\\   \"V\" 
 | I hope you find what  |    \/__\\   I  
 | you are looking for.  |   \/\/..\\\\  I  
 |                       |-- \\].\`[\/  I
 | If in doubt, ask ATC. |   \/l\\\/j\\  (] 
 _________________________  \/. ~~ ,\\\/I  
                            \\\\L__j^\\\/I  
                             \\\/--v}  I  
                             |    |  I  
                             |    |  I  
                             |    l  I
                           _\/j  L l\\_! 
`;

console.log(wizzard);
